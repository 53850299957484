/* Contact.css */
.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Set a height for centering vertically */
  }
  
  .Contact {
    /* Add other styles for the Contact component if needed */
  }
  