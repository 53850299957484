/* Navbar.css */
.navbar {
    position: sticky;
    top: 0;
    color: black; /* Set the text color to black */
    background-color: #f5f3f3;
    padding-left: 1vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    z-index: 10000;
  }
  
.logo {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Times New Roman', serif;
    margin-left: 5px;
    text-decoration: none;
    color: black; /* Set font color to black */
  }

.logo a {
  text-decoration: none;
  color: black;
}

.logo:hover {
  transform: scale(1.07); /* Increase the scale factor for scaling up */
  transition: transform 0.3s ease-in-out;
}

.logo:not(:hover) {
  transform: scale(1); /* Return to the original size */
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}
  
  .nav-links {
    list-style: none;
    padding: 0;
    display: flex;

  }
  
  .nav-links li {
    margin-right: 2vw; /* Add space to the right of each link */
  }
  
  .nav-links li:first-child {
    margin-left: 0;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: black; /* Set the link text color to black */
    font-family: 'Times New Roman', serif;
  }
  
  .nav-links li:hover {
    text-decoration: overline;
    transition: all 0.3s ease-in-out;
  }
  
  /* Optional: Add transition for a smooth effect */
  .nav-links li {
    transition: all 0.3s ease-in-out;
  }

@media only screen and (max-width: 768px) {
    .logo {
      font-size: 18px; /* Adjust the font size for smaller screens */
    }
  
    .nav-links li a {
      font-size: 14px; /* Adjust the font size for smaller screens */
    }
  }

  @media screen and (max-width: 600px) {
    
    .logo {
      font-size: 16px; /* Adjust the font size for smaller screens */
    }

    .nav-links li {
      margin-right: 3vw; /* Add space to the right of each link */
    }
    .nav-links li a {
      font-size: 12x; /* Adjust the font size for smaller screens */
    }

}

@media screen and (max-width: 400px) {

  .logo {
    margin-left: 3px;
    margin-right: 3vw;
  }

  .nav-links li {
    margin-right: 3vw; /* Add space to the right of each link */
  }
  .nav-links li a {
    font-size: 10x; /* Adjust the font size for smaller screens */
  }

}