/* Slide.css */
.spain-slide {
    display: flex;
    justify-content: center;
    text-align: center; /* Center text within the container */
  }
  
  .spain-slide-image {
    text-align: center;
  }

  .spain-slide-image img:hover {
    transform: scale(1.20); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
  }
  .spain-slide-image img:not(:hover) {
    transform: scale(1); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
  }
  
  .spain-slide-image img {
    max-width: 50vw;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  }
  
  .spain-slide-image p {
    margin-top: 5px; /* Add margin to separate the title/description from the image */
    font-size: 16px; /* Adjust font size as needed */
    font-style: italic;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .spain-slide-image p {
      font-size: 14px;
  }
  }
  
  @media screen and (max-width: 767px) {
    .spain-slide-image p {
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
  .spain-slide-image p {
      font-size: 10px;
  }
}

