/* Home.css */
body {
    background-color: #f8f8f8; /* Set the background color to a slight off-white for the entire body */
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
}

.page {
    min-height: 100vh; /* Ensure the page takes at least the full viewport height */
}

.spring-spain-title {
    font-style: italic;
    margin-top: 20vh;
    
}

.Home {
    text-align: center; /* Optional: Center the image within the container */
}
  
.spring-title-photo {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  }

.spring-title-photo:hover {
    transform: scale(1.05); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
}

.spring-title-photo:not(:hover) {
    transform: scale(1); /* Return to the original size */
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}

.north-woods-title {
    margin-top: 3vh;
    font-style: italic;
}

.north-woods-title-photo {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-bottom: 5vh;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
  }

.north-woods-title-photo:hover {
    transform: scale(1.05); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
}

.north-woods-title:not(:hover) {
    transform: scale(1); /* Return to the original size */
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}

.reinterpretation-title-photo {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-bottom: -17vh;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
}
.reinterpretation-title-photo:hover {
    transform: scale(1.05); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
}

.reinterpretation-title-photo:not(:hover) {
    transform: scale(1); /* Return to the original size */
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}


@media screen and (max-width: 600px) {
    
    .spring-spain-title {
        font-style: italic;
        margin-top: 20vh;
        font-size: 14px;
    }

    .north-woods-title {
        margin-top: 3vh;
        font-style: italic;
        font-size: 14px;
    }

}