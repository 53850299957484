body {
    background-color: #f8f8f8; /* Set the background color to a slight off-white for the entire body */
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
}

.personal-page {
    min-height: 100vh; /* Ensure the page takes at least the full viewport height */
}

.spain-content {
    margin-top: 25vh;
    text-align: center;
}

.spain-content h1 {
    font-size: 20px;
}

.awe {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
}

.awe:hover {
    transform: scale(1.05); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
}

.awe:not(:hover) {
    transform: scale(1); /* Return to the original size */
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}

.rockies-content {
    margin-top: 5vh;
    text-align: center;
    margin-bottom: 5vh;
}

.rockies-content h1 {
    font-size: 20px;
}

.impossible {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
}

.impossible:hover {
    transform: scale(1.05); /* Increase the scale factor for scaling up */
    transition: transform 0.3s ease-in-out; /* Adjust the easing function for a smooth transition */
}

.impossible:not(:hover) {
    transform: scale(1); /* Return to the original size */
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling up and down */
}

@media screen and (max-width: 768px) {
    
    .spain-content h1 {
        margin-top: 25vh;
        font-size: 16px;
    }

    .rockies-content h1 {
        margin-top: 25vh;
        font-size: 16px;
    }

  }

@media screen and (max-width: 600px) {
    
    .spain-content h1 {
        margin-top: 25vh;
        font-size: 14px;
    }

    .rockies-content h1 {
        margin-top: 25vh;
        font-size: 14px;
    }

}
