.spain-centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Optional: Center vertically within the viewport */
    margin-top: -20vh;
  }
  

.spain-artist-statement-card {
    border-radius: 10px; /* Set the border-radius for rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for depth */
    padding: 15px; /* Add padding for spacing inside the card */
    background-color: #fafafa; /* Set the background color of the card */
    margin-bottom: 20px; /* Optional: Add margin to separate the card from other elements */
    text-align: center;
}

.spain-artist-statement-card p {
    line-height: 1.5;
}

.spain-image-container {
    margin-top: -38vh;
    text-align: center;
}

.spain-image-container p {
    font-style: italic;
}

.spain-images {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px; /* Adjust as needed for spacing */
    margin-bottom: 5vh;
  }
  
  .button-container button {
    background: none;
    border: none;
    padding: 10px;
    margin: 0 10px; /* Adjust as needed for space between buttons */
    cursor: pointer;
}

.button-container img {
    width: 30px; /* Adjust the size of the SVG image */
    height: 30px;
}

@media screen and (max-width: 767px) {

    .spain-artist-statement-card h2 {
        font-size: 16px;
    }

    .spain-artist-statement-card p {
        font-size: 12px;
        line-height: 1.5;
    }
    .button-container button {
        padding: 5px;
        font-size: 12px;
        margin: 0 10px; /* Adjust as needed for space between buttons */
    }
    .button-container img {
        width: 20px; /* Adjust the size of the SVG image */
        height: 20px;
    }
}

@media screen and (max-width: 600px) {

    
    .button-container button {
        padding: 5px;
        font-size: 10px;
        margin: 0 10px; /* Adjust as needed for space between buttons */
    }
    .button-container img {
        width: 15px; /* Adjust the size of the SVG image */
        height: 15px;
    }

}