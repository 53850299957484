/* About.css */
  .About p {
    margin-bottom: 16px; /* Add margin between paragraphs if desired */
    text-align: center; /* Align the text to the left within the centered container */
    line-height: 1.5;
    width: 80vw;
    margin-left: 10vw;
  }

.about-title {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
    margin-bottom: 5vh;
}

@media screen and (max-width: 1023px) {
  p {
    font-size: 14px; /* Adjust font size for smaller screens */
    line-height: 1.5; /* Set line height for better readability */
  }
}

@media screen and (max-width: 600px) {

  .about-title {
    max-width: 50vw; /* Set the maximum width to 80% of the viewport width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Ensure that it behaves as a block element */
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
}

  p {
    font-size: 11px; /* Adjust font size for smaller screens */
    line-height: 1.5; /* Set line height for better readability */
  }

}

@media screen and (max-width: 400px) {
  
  p {
    font-size: 9px; /* Adjust font size for smaller screens */
    line-height: 1.5; /* Set line height for better readability */
  }
}