/* Slide.css */
.single-slide {
    display: flex;
    justify-content: center;
    align-items: center; /* Center content vertically */
    text-align: center; /* Center text within the container */
    margin-top: -30vh;
  }
  
  .single-slide-image {
    margin: 10px; /* Add margin for spacing between image containers */
    text-align: center;
  }
  
  .single-slide-image img {
    max-width: 50vw;
    height: auto;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a slight shadow */
}
  
  .single-slide-image p {
    margin-top: 5px; /* Add margin to separate the title/description from the image */
    font-size: 16px; /* Adjust font size as needed */
    font-style: italic;
  }

 
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .single-slide-image p {
      font-size: 14px;
  }
  }
  
  @media screen and (max-width: 767px) {
    .single-slide-image p {
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {
  .single-slide-image p {
      font-size: 10px;
  }
}

